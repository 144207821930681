import React, { Component } from 'react';

class SupportPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <div>
      <h1>Welcome to Gaia-x support</h1>
    </div>
  }
}

export default SupportPage;
