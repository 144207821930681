export const SIGN_IN_MENU = 'SIGN_IN_MENU';
export const NOT_SIGN_IN_MENU = 'NOT_SIGN_IN_MENU';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const UPDATE_SEARCH_FILTER_CRITERIA = 'UPDATE_SEARCH_FILTER_CRITERIA';
export const UPDATE_SEARCH_PAGE_NUMBER = 'UPDATE_SEARCH_PAGE_NUMBER';
export const UPDATE_SEARCH_TYPE = 'UPDATE_SEARCH_TYPE';
export const UPDATE_SEARCH_TYPE_AND_TERM = 'UPDATE_SEARCH_TYPE_AND_TYPE';
export const UPDATE_SEARCH_FROM_HOME = 'UPDATE_SEARCH_FROM_HOME';
export const UPDATE_SELECTED_PAGE = 'UPDATE_SELECTED_PAGE';
export const SET_DESCRIPTOR_FILE = 'SET_DESCRIPTOR_FILE';
export const RESET_DESCRIPTOR_FILE = 'RESET_DESCRIPTOR_FILE';
export const LCM_SERVICES_LOADED = 'LCM_SERVICES_LOADED';
export const RESET_LCM_SERVICES = 'RESET_LCM_SERVICES';
export const LCM_SELECT_SERVICE = 'LCM_SELECT_SERVICE';
export const CHANGE_USER_ROLE = 'CHANGE_USER_ROLE'

